<template>
  <div :class="{ node: true, root: isRoot || !show, 'node-error-state': showError }">
    <div v-if="show" @click="$emit('selected')" :class="{ 'node-body': true, error: showError }">
      <div :style="{ background: headerBgc, padding: '6px', borderRadius: '10px' }">
        <!-- 头部 -->
        <div class="node-body-header">
          <i :class="headerIcon" style="margin-right: 5px" v-if="(headerIcon || '') !== ''"></i>
          <ellipsis class="name" hover-tip :content="title" />
          <i
            class="iconfont icon-cha1 el-icon-close"
            v-if="!isRoot && diagramMode !== 'viewer' && !allDisabled"
            style="float:right;"
            @click="$emit('delNode')"
          ></i>
        </div>
        <!-- 主要内容 -->
        <div class="node-body-content">
          <div class="node-content-item">
            {{ content }}
          </div>
          <i  v-if="content!=='结束流程'" class="iconfont icon-right icon-rght"></i>
        </div>
      </div>
      <div class="no-err" v-if="showError">
        <img src="@/assets/images/business/tip.png" alt="" />
      </div>
    </div>
    <div class="node-footer" v-if="!isFooter">
      <div class="btn">
        <insert-button
          v-show="diagramMode !== 'viewer'"
          :allDisabled="allDisabled"
          @insertNode="type => $emit('insertNode', type)"
        ></insert-button>
      </div>
    </div>
  </div>
</template>

<script>
import InsertButton from '../../InsertButton.vue'

export default {
  name: 'Node',
  components: { InsertButton },
  props: {
    //是否为根节点
    isRoot: {
      type: Boolean,
      default: false
    },
    //是否显示节点体
    show: {
      type: Boolean,
      default: true
    },
    //节点内容区域文字
    content: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '标题'
    },
    placeholder: {
      type: String,
      default: '请设置'
    },
    //节点体左侧图标
    leftIcon: {
      type: String,
      default: undefined
    },
    //头部图标
    headerIcon: {
      type: String,
      default: ''
    },
    //头部背景色
    headerBgc: {
      type: String,
      default: '#576a95'
    },
    //是否显示错误状态
    showError: {
      type: Boolean,
      default: false
    },
    errorInfo: {
      type: String,
      default: '无信息'
    },
    isFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    diagramMode() {
      return this.$store.state.process.diagramMode
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  created() {
    //   console.log(this.$store.state.process.diagramMode);
    //  console.log('1111node组件');
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.root {
  &:before {
    display: none !important;
  }
}
.node-error-state {
  .node-body {
    box-shadow: 0px 0px 10px 0px rgba(214, 0, 0, 0.4) !important;
  }
}
.node {
  // padding: 0 50px;
  width: 220px;
  margin: 0px auto;
  position: relative;
  .node-body {
    width: 220px;
    cursor: pointer;
    max-height: 120px;
    position: relative;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #d8d8d8;
    &:hover {
      box-shadow: 0px 0px 3px 0px#1890FF;
      .node-body-header {
        .el-icon-close {
          display: inline;
          font-size: medium;
        }
      }
    }
    .node-body-header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 5px;
      color: white;
      font-size: xx-small;
      .el-icon-close {
        display: none;
      }
      .name {
        line-height: 22px;
        display: inline-block;
        font-size: 16px;
      }
    }
    .node-body-content {
      padding: 18px;
      color: #656363;
      font-size: 14px;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      .node-content-item {
        // width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon-rght {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .no-err {
      position: absolute;
      right: -32px;
      bottom: 40%;
      img {
        width: 16px;
      }
    }
  }

  .node-footer {
    position: relative;
    .btn {
      width: 100%;
      display: flex;
      padding: 20px 0 32px;
      justify-content: center;
    }
    /deep/ .el-button {
      height: 32px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      width: 1px;
      height: 100%;
      background-color: #e1e1e1;
    }
  }
}
</style>
